import React, { useEffect, useRef } from 'react';
import { FormikProps } from 'formik';

import {
    container,
    toggleButtonContent,
    toggleButtonIcon,
    clearButton,
} from './candidate-survey-filter.module.scss';
import { IFilters } from '../../models/filter.model';
import { ICandidate } from '../../models/candidate.model';
import useTranslations from '../../hooks/use-translations';
import { useCandidate } from '../../hooks/use-candidate';

import Button from '../atoms/button';
import IconFactory from '../hoc/icon-factory';
import Tooltip from '../atoms/tooltip';

interface ICandidateSurveyFilterProps {
    className?: string;
    toggleButtonText?: string;
    hint?: string;
    clearButtonText?: string;
    formik: FormikProps<any> | null;
    filters: IFilters | undefined | null;
    isInitiallyApplied?: boolean;
    addScoreSortInitially?: boolean;
}

const CandidateSurveyFilter: React.FC<ICandidateSurveyFilterProps> = ({
    className = '',
    clearButtonText,
    toggleButtonText,
    formik,
    hint,
    filters,
    isInitiallyApplied,
    addScoreSortInitially,
}) => {
    const candidate = useCandidate();
    const t = useTranslations('CandidateSurveyFilter');
    const appliedRef = useRef(false);

    const localToggle = toggleButtonText || t.toggle;
    const localClear = clearButtonText || t.clear;

    const surveyParams = getCandidateSurveyParams(filters, candidate.data, addScoreSortInitially);
    const isSurveyParamsSelected =
        JSON.stringify(getValuesWithoutSort(formik?.values)) ===
        JSON.stringify(getValuesWithoutSort(surveyParams));

    const handleToggleSurveyParams = () => {
        if (!formik) return;
        formik.setValues(isSurveyParamsSelected ? {} : surveyParams);
    };

    const handleClearSurveyParams = () => {
        if (!formik) return;
        formik.setValues({});
    };

    useEffect(() => {
        if (window.location.search) {
            appliedRef.current = true;
            return;
        }
        if (!formik || appliedRef.current) return;
        if (isInitiallyApplied) {
            formik.setValues(surveyParams);
            appliedRef.current = true;
        }
    }, [formik, isInitiallyApplied, surveyParams]);

    return (
        <div className={`${container} ${className}`}>
            <Button
                onClick={handleToggleSurveyParams}
                stylePreset={isSurveyParamsSelected ? 'primary' : 'secondary'}
                isNoHover={true}
            >
                <span className={toggleButtonContent}>
                    <IconFactory className={toggleButtonIcon} icon="checkmark" />
                    {localToggle}
                </span>
            </Button>
            {isSurveyParamsSelected && (
                <button className={clearButton} onClick={handleClearSurveyParams}>
                    {localClear}
                </button>
            )}
            {hint && <Tooltip>{hint}</Tooltip>}
        </div>
    );
};

function getValuesWithoutSort(values: Record<string, string | string[]> | undefined | null) {
    if (!values) return;
    const newValues = { ...values };
    if (newValues.sort) {
        delete newValues.sort;
    }
    return newValues;
}

export function getCandidateSurveyParams(
    filters: IFilters | null | undefined,
    candidate: ICandidate | undefined | null,
    addScoreSortInitially: boolean | undefined
): Record<string, string[] | string> | undefined {
    if (!filters || !candidate) return;
    const params = Object.values(filters).reduce<Record<string, string | string[]>>(
        (acc, filter) => {
            const key = filter.paramName;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const answer = candidate[key] as string | number | string[] | undefined | null;
            if (answer !== undefined && answer !== null) {
                acc[key] = Array.isArray(answer) ? answer : answer.toString();
            }
            return acc;
        },
        {}
    );
    if (!params.sort && addScoreSortInitially) {
        params.sort = '-score';
    }
    return params;
}

export default CandidateSurveyFilter;
