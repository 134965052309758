// extracted by mini-css-extract-plugin
export var accepted = "candidate-application-listing-module--accepted--466e6";
export var backgroundAnimation = "candidate-application-listing-module--backgroundAnimation--a27a8";
export var grid = "candidate-application-listing-module--grid--f45be";
export var loader = "candidate-application-listing-module--loader--2df8a";
export var rejected = "candidate-application-listing-module--rejected--733f2";
export var sectionBox = "candidate-application-listing-module--section-box--b311a";
export var table = "candidate-application-listing-module--table--0d19d";
export var tableGrid = "candidate-application-listing-module--table-grid--a9f2d";
export var tableWrapper = "candidate-application-listing-module--table-wrapper--9580b";
export var waiting = "candidate-application-listing-module--waiting--ba7fe";