import React, { useRef } from 'react';
import { Formik, FormikProps } from 'formik';

import {
    sectionBox,
    loader,
    accepted,
    waiting,
    rejected,
    tableWrapper,
    grid,
    table,
    tableGrid,
} from './candidate-application-listing.module.scss';
import { ISection } from '../../models/section.model';
import { INavLink } from '../../models/nav-link.model';
import { ICandidateApplication } from '../../models/candidate-application';
import { TLocale } from '../../locale';
import { useList } from '../../hooks/use-list';
import { getUserTokenData } from '../../utils/get-user-token-data';
import { getDateFromUnixTimestamp } from '../../utils/get-date-from-unix-timestamp';
import useTranslations from '../../hooks/use-translations';

import Section from '../hoc/section';
import Loader from '../atoms/loader';
import HandleFormikChange from '../hoc/handle-formik-change';
import Table, { ITableProps } from '../organisms/table';
import CandidateSurveyFilter from '../organisms/candidate-survey-filter';

interface ICandidateApplicationsSection extends ISection {
    items: {
        mainMenuLinks: INavLink[];
    };
}

interface ICandidateApplicationsProps {
    section: ICandidateApplicationsSection;
}

const CandidateApplicationListing: React.FC<ICandidateApplicationsProps> = ({ section }) => {
    const { style, css, sectionId } = section;
    const tokenData = getUserTokenData();
    const t = useTranslations('CandidateApplicationsListing');
    const formikRef = useRef<FormikProps<any>>(null);

    const {
        status,
        isInitialLoading,
        items,
        paginationPaths,
        filters,
        handleChange,
        values,
        pagination,
    } = useList<ICandidateApplication>({
        endpoint: '/candidates/applications',
        token: tokenData?.token,
        perPage: 6,
    });

    return (
        <Section
            className={sectionBox}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            {isInitialLoading && <Loader className={loader} />}
            {!isInitialLoading && (
                <Formik
                    onSubmit={() => {}}
                    initialValues={values || {}}
                    enableReinitialize={true}
                    innerRef={formikRef}
                >
                    <div className={tableWrapper}>
                        <HandleFormikChange onChange={handleChange} />
                        <CandidateSurveyFilter
                            filters={filters}
                            formik={formikRef.current}
                            hint={t.savedHint}
                        />
                        <Table
                            className={table}
                            status={status}
                            filters={filters}
                            paginationPaths={paginationPaths}
                            tableClassName={tableGrid}
                            totalCount={pagination?.totalCount || 0}
                            headerCells={getHeaderCells(t)}
                            rows={getApplicationRows(t, items)}
                        />
                    </div>
                </Formik>
            )}
        </Section>
    );
};

function getHeaderCells(t: TLocale['CandidateApplicationsListing']): ITableProps['headerCells'] {
    return [
        { label: t.name },
        { label: t.company },
        { label: t.place },
        { label: t.created },
        { label: t.expired },
        { label: t.status.label },
        { label: t.match },
        { label: '' },
    ];
}

function getApplicationRows(
    t: TLocale['CandidateApplicationsListing'],
    applications: ICandidateApplication[]
): ITableProps['rows'] {
    return applications.map((application) => {
        return [
            {
                type: 'data',
                label: t.name,
                value: application.advertisementName,
                valueStyle: 'bold',
            },
            {
                type: 'data',
                label: t.company,
                value: application.companyName,
            },
            {
                type: 'data',
                label: t.place,
                value: application.city,
            },
            {
                type: 'data',
                label: t.created,
                value: getDateFromUnixTimestamp(application.createdAt, 'dash'),
            },
            {
                type: 'data',
                label: t.expired,
                value: getDateFromUnixTimestamp(application.finishedAt, 'dash'),
            },
            {
                type: 'data',
                label: t.status.label,
                value: getStatusTranslation(application.status, t) || '',
                valueClassName:
                    application.status === 1 || application.status === 0
                        ? waiting
                        : application.status === 2
                        ? accepted
                        : rejected,
            },
            {
                type: 'data',
                label: t.match,
                value: `${application.score ? application.score.toString() : 0}%`,
                valueStyle: 'accent',
            },
            {
                type: 'action',
                actions: [
                    {
                        as: 'link',
                        to: application.pathname,
                        stylePreset: 'secondary',
                        size: 'medium',
                        children: t.button,
                    },
                ],
            },
        ];
    });
}

function getStatusTranslation(status: number, t: TLocale['CandidateApplicationsListing']) {
    switch (status) {
        case 0:
            return t.status.waiting;
        case 1:
            return t.status.waiting;
        case 2:
            return t.status.accepted;
        case 3:
            return t.status.rejected;
    }
}

export default CandidateApplicationListing;
